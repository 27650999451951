mm-modal-layout {
  mm-panel-header {
    padding: 24px;
    border: none;
    .panel-header-title {
      font-size: @font-extra-extra-large;
      font-weight: normal;
    }
  }

  .modal-body {
    overflow-y: auto;
    min-height: 50px;
    max-height: 75vh;
    padding: 0 24px;
    textarea {
      margin: 0;
      width: 100%;
      padding: 5px;
    }

    .datepicker {
      max-width: 300px;
    }

    .help-block > label {
      color: @mat-font-color;
      font-weight: normal;
    }

    .has-error .form-control {
      border-color: @error-color;
    }

    .modal-footer {
      padding: 24px 0;
    }
  }

  .modal-footer {
    border: none;
    padding: 24px;
    .mat-mdc-button,
    .mat-mdc-unelevated-button {
      margin-left: 5px;
    }
  }

  .modal-error,
  .has-error > .help-block{
    color: @error-color;
    width: 100%;
    text-align: left;
  }

  .enketo-modal {
    &.content-pane {
      position: relative;
    }

    .item-content {
      padding: 0;
      background: none;
    }

    .enketo {
      .or h2,
      .or h3,
      .or h4,
      .form-footer,
      .label,
      .question,
      .note,
      .invalid-constraint,
      .invalid-required,
      .or-appearance-android-app-launcher .android-app-launcher-actions {
        padding-left: 0;
        padding-right: 0;
      }
      .or-appearance-summary {
        .or-appearance-h1:not(.disabled) {
          padding-left: 5px;
          padding-right: 5px;
        }
        .or-appearance-li:not(.disabled):before {
          left: -10px;
        }
      }
    }

    .form-no-title #form-title {
      display: none;
    }

    .form-footer {
      padding: 24px 0;
      .btn,
      .btn:active,
      .btn:focus {
        outline: none;
        box-shadow: none;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: @media-mobile) {
  mm-modal-layout {
    .enketo {
      #form-title {
        display: inherit;
      }

      .form-footer {
        .btn.btn-link.cancel {
          display: inline-block;
        }

        .previous-page {
          float: none;
        }
      }
    }
  }
}

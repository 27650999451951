@import "variables";

@font-face {
  font-family: 'Noto';
  src: url(/fonts/NotoSans-Regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: 'Noto';
  src: url(/fonts/NotoSans-Bold.ttf) format("truetype");
  font-weight: bold;
}

body {
  font-family: @font-family-main;
  color: @text-normal-color;
}

html,
.input-group-btn { /* for some reason bootstrap sets this to 0 */
  font-size: 16px;
}

body,
.app-root,
.dropdown-menu,
.btn,
.form-control {
  font-size: 100%;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
}

h1 {
  font-size: @font-extra-extra-large;
}

h2 {
  font-size: @font-extra-large;
}

h3 {
  font-size: @font-large;
}

h4 {
  font-size: @font-medium;
  line-height: 1;
}

.small-font {
  font-size: @font-small;
}

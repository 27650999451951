error-log > * {
  margin: 10px;
  .error-details {
    a {
      float: right;
    }
    dt {
      padding-top: 6px;
    }
  }
}

error-log.targets {
  padding: 0 0 0 0;
}

.partner-image {
  img {
    max-height: 150px;
    max-width: 150px;
    margin: 40px;
  }
}

.form-group.required label::after {
  content: '*';
  margin-left: 5px;
}

.form-actions,
.form-group,
.form-footer {
  .error {
    color: @error-color;
    font-weight: bold;
  }
}
.form-footer .error {
  text-align: right;
  clear: right;
}
.form-actions {
  line-height: 20px;
  .success {
    display: inline-block !important;
    opacity: 1;
    transition: all linear 0s;
    &.ng-hide, &.ng-hide-add.ng-hide-add-active {
      opacity: 0;
    }
    &.ng-hide-add {
      transition: all linear 1s;
      opacity: 1;
    }
  }
}

ol,
ul {
  margin: 0;
  padding: 0;
  &.horizontal li {
    display: inline;
  }
}

.sender {
  .position {
    color: @label-color;
    white-space: normal;
  }
  .name {
    font-weight: bold;
  }
}

.select2-results,
.select2-choice {
  .sender span {
    margin-right: 10px;
  }
  .everyone-at {
    font-weight: bold;
  }
  .fa {
    color: @ok-color;
  }
}
.select2-results .select2-highlighted .sender .position {
  color: #ffffff;
}

.lineage {
  margin-bottom: 0;
  display: -webkit-box;
  // limit the lineage wrapping to two lines
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  li{
    white-space: normal;
  }
  li:after {
    content: '\2022'; /* bullet */
    padding: 0 5px;
  }
}

.lineage li:last-child:after {
  content: '';
  padding: 0;
}

@import "variables";

/**
* @name mm-icon:
*/

.mm-icon {
  cursor: pointer;
  text-align: center;
  position: relative;
  &, &:focus {
    .button-text;
  }
  &:hover, &.active {
    .button-text-hover;
  }
}

.mm-icon-inverse {
  &, &:focus {
    .button-text-inverse;
  }
}

.mm-icon-disabled {
  &, &:hover, &:focus {
    text-decoration: none;
    cursor: default;
    pointer-events: none;
    .button-text-disabled;
  }
}

.mm-icon-caption {
  font-size: @font-small;
  font-weight: bold;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.loader {
  margin: 10px auto;
  position: relative;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgba(0, 0, 0, 0.5);
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  &.inline {
    vertical-align: middle;
    display: inline-block;
    margin: 10px;
  }
  &.small {
    width: 20px;
    height: 20px;
    border-width: 3px;
  }
}
@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.message-error {
  color: @failed-state-color;
  font-size: @font-small;

  &:before {
    font-family: FontAwesome;
    margin-right: 5px;
    vertical-align: baseline;
    content: @failed-state-icon;
  }
}

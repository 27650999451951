@import (reference) "private-vars";

/* app colors */
@mm-mat-primary: @blue-dark;
@mat-icon-color: @gray-ultra-dark;
@mat-invert-icon-color: @white;
@mat-font-color: @gray-ultra-dark;

@top-header-color: @gray-ultra-dark;
@background-color: @gray-ultra-light;
@target-card-background-color: @gray-ultra-lighter;
@target-card-border-color: @gray-light;
@form-background-color: @white;
@list-highlight-color: @gray-ultra-light;
@separator-color: @gray-light;
@separator-dark-color: @gray-medium;
@button-text-color: @gray-ultra-dark;
@button-text-inverse-color: @gray-light;
@button-background-inverse-color: @white;
@text-normal-color: @gray-ultra-dark;
@label-color: @gray-dark;
@text-secondary-color: @gray-dark;
@text-link-color: @blue-dark;
@text-link-active-color: @blue-ultra-dark;
@ok-color: @green;
@error-color: @red;
@high-risk-color: @red;
@unread-color: @blue-dark;
@contacts-primary-color: @yellow-dark;
@inactive-color: @gray-medium;
@targets-below-color: @black;
@targets-above-goal: @teal;
@report-review-error-color: @red;
@report-review-verified-color: @teal-dark;
@overdue-color: @red;
@button-color: @blue-dark;
@checkbox-selected-color: @blue-dark;
@checkbox-border-color: @gray-medium;
@chip-background-color: @blue-dark;
@chip-text-color: @white;
@chip-border-color: @white;
@sidebar-background-color: @white;
@filter-icon-color: @gray-ultra-dark;
@filter-text-color: @gray-medium;
@progress-bar-background-color: @white;
@targets-progress-bar-unmet-goal: @gray-medium-dark;
@backdrop-color: @black;
@enketo-hover-color: @blue-highlight;
@shadow-color: @gray-light;
@tooltip-background: @gray-ultra-dark;
@tooltip-text: @gray-light;
@target-progress-bar-border-color: @white;
@targets-progress-bar-background-color: @gray-medium-dark;

/* tab colors */
@messages-color: @blue;
@messages-highlight: @blue-highlight;
@tasks-color: @periwinkle;
@tasks-highlight: @periwinkle-highlight;
@contacts-color: @pink;
@contacts-highlight: @pink-highlight;
@reports-color: @yellow;
@reports-highlight: @yellow-highlight;
@analytics-color: @teal;
@analytics-highlight: @teal-highlight;
@admin-color: @gray-dark;
@help-color: @gray-dark;

/* state colors */
@scheduled-state-color: @blue;
@pending-state-color: @yellow;
@sent-state-color: @teal-dark;
@muted-state-color: @gray-medium;
@failed-state-color: @red;
@cleared-state-color: @gray-medium;
@denied-state-color: @gray-medium;
@delivered-state-color: @teal-dark;
@forwarded-to-gateway-state-color: @yellow;
@received-by-gateway-state-color: @yellow;
@forwarded-by-gateway-state-color: @yellow;

/* Visit count progress colors */
@visits-goal-incomplete: @yellow-dark;
@visits-goal-done: @teal-dark;

/* sizes */
@radius-size: 10px;
@radius-size-medium: 8px;
@radius-size-small: 4px;
@content-width: 1170px;
@content-margin: calc((100vw - @content-width) / 2);
@admin-content-width: 1440px;
@media-tablet: 985px;
@media-mobile: 767px;
@media-small-mobile: 400px;
@header-dropdown-width: 250px;
@header-bar-extras: 40px;

/* fonts */
@font-family-main: Noto, sans-serif;

/* font sizes */
@font-extra-extra-large: 1.5rem;
@font-extra-large: 1.25rem;
@font-large: 1.125rem;
@font-medium: 1rem;
@font-small: 0.875rem;
@font-extra-small: 0.8125rem;
@font-extra-extra-small: 0.625rem;

@icon-small: 30px;
@icon-large: 60px;

/* icons */
@scheduled-state-icon: '\f073';
@muted-state-icon: '\f1f6';
@cleared-state-icon: '\f00d';
@pending-state-icon: '\f017';
@forwarded-to-gateway-state-icon: '\f064';
@received-by-gateway-state-icon: '\f381';
@forwarded-by-gateway-state-icon: '\f382';
@sent-state-icon: '\f0e0';
@delivered-state-icon: '\f058';
@denied-state-icon: '\f056';
@failed-state-icon: '\f071';
@high-risk-icon: '\f071';
@upcoming-edd-icon: '\f111';

/* margins */
@targets-column-gap: 24px;

/* functions */
.button-text() {
  color: @button-text-color;
  text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 2px;
  svg {
    filter: drop-shadow(1px 1px 2px rgba(255, 255, 255, 0.5));
    circle,
    path {
      fill: @button-text-color;
    }
  }
}

.button-text-hover() {
  color: @white;
  svg {
    circle,
    path {
      fill: @white;
    }
  }
}

.button-text-disabled() {
  color: @label-color;
  svg {
    circle,
    path {
      fill: @label-color;
    }
  }
}

.button-text-inverse() {
  color: @button-text-inverse-color;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2px;
  svg {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
    circle,
    path {
      fill: @button-text-inverse-color;
    }
  }
}

@import "variables";

.mm-badge-border {
  border: solid 1px #fff;
}

.mm-badge {
  background-color: #fff;
  text-align: center;
  border-radius: 100px;
  padding: 2px 6px;
  margin: 5px;
  vertical-align: top;
  display: inline-block;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2px;

  .fa {
    text-shadow: none;
  }
}

.mm-badge-red {
  color: #fff;
  background-color: @error-color;
}

.mm-badge-green {
  color: #fff;
  background-color: @ok-color;
}

.mm-badge-overlay {
  overflow: visible;
  position: absolute;
  z-index: 100;
  font-size: 0.5rem;
  margin: 0;
  right: 15%;
}

.mm-badge-overlay-top {
  top: -3px;
}

.verification-badge {
  svg {
    width: 14px;
    height: 14px;
  }
  &.verified svg path {
    fill: @report-review-verified-color;
  }
  &.error svg path {
    fill: @report-review-error-color
  }
}

@import "variables";

.mm-button {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  min-height: 40px;
  overflow: hidden;
  padding-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 1px;
  background-color: rgba(255, 255, 255, 0.45);
}

.mm-button-inverse {
  background-color: rgba(255, 255, 255, 0.2);
  border: solid 1px rgba(255, 255, 255, 0.1);
  box-shadow: rgba(255, 255, 255, 0.1) 1px 1px 1px;
}

.mm-button-dropdown {
  color: #333;
  float: right;
  padding: 10px 10px 0 0;
  cursor: pointer;
}

.mm-button-inverse {
  .mm-button-dropdown, .mm-button-icon, .mm-button-text {
    color: #eee;
  }
  .mm-button-icon {
    text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
  }
  .mm-button-text {
    text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 1px;
  }
}

.mm-button-icon {
  float: left;
  color: #222;
  font-size: 1.4rem;
  text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 1px;
  padding: 5px 5px 5px 0;
}

.mm-button-text {
  color: #222;
  font-weight: normal;
  padding-right: 32px;
  text-overflow: ellipsis;
  vertical-align: bottom;
  text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 1px;
}

span.mm-button-text {
  line-height: 40px;
  vertical-align: middle;
}

div.mm-button-text {
  padding: 0.95em 0 0 0;
}

.mm-button-disabled .mm-button-text,
.mm-button-disabled .mm-button-icon,
.mm-button-disabled .mm-button-dropdown {
  opacity: 0.7;
}

.mm-button-disabled {
  cursor: auto;
  background-color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}

.mm-button-disabled.mm-button-inverse {
  background-color: rgba(255, 255, 255, 0.1);
}

.mm-navigation-menu {
  text-align: center;
  margin: 10px auto;
  li {
    width: 200px;
    vertical-align: top;
    font-size: @font-extra-extra-large;
    margin: 20px;
    display: inline-block;
    a {
      color: inherit;
      cursor: pointer;
      background-color: @separator-color;
      border: 1px solid @admin-color;
      border-radius: @radius-size;
      box-sizing: border-box;
      text-align: center;
      padding: 5px;
      width: 100%;
      min-height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:hover {
        text-decoration: none;
      }
      .fa {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: @media-mobile) {
  .mm-navigation-menu li {
    font-size: @font-large;
  }
}

@media (max-width: @media-small-mobile) {
  .mm-navigation-menu li {
    margin: 10px;
  }
}

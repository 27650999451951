.about {
  mat-card {
    margin: 15px 0;
  }

  mat-card-content {
    word-break: break-all;
    label {
      word-break: break-word;
      margin-top: 1em;
    }
  }

  .mat-mdc-card {
    border-radius: @radius-size-small;
  }

  .mat-mdc-card-header {
    padding: 16px;
  }

  .browser-support-message {
    word-break: break-word;
  }

  .browser-support-icon-red {
    color: @error-color;
  }

  .browser-support-icon-green {
    color: @ok-color;
  }

  .powered-by {

    text-align: center;
    padding: 15px;

    img {
      width: 100%;
      max-width: 280px;
    }
  }

}

.targets {
  display: grid;
  grid-template-columns: repeat(3, 296px);
  gap: 0 @targets-column-gap;
  justify-content: center;
  padding: 35px 0;

  .target {
    width: 296px;
    min-height: 151px;
    background-color: @target-card-background-color;
    border: 1px solid @target-card-border-color;
    border-radius: @radius-size-medium;
    margin: 10px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .heading {
      display: flex;
      padding:  15px 10px;
      background-color: @white;
      border-bottom-left-radius: @radius-size-medium;
      border-bottom-right-radius: @radius-size-medium;
      border-top: 1px solid @target-card-border-color;
      min-height: 70px;
      .title {
        h2 {
          margin: 0;
          color: @gray-ultra-dark;
          font-size: @font-medium;
          font-weight: 700;
          line-height: 20px;
        }
        p {
          color: @gray-dark;
          font-size: @font-small;
          font-weight: normal;
          margin: 0;
        }
      }
      .icon {
        margin-right: 10px;
        img,
        svg {
          width: 33px;
          height: 31px;
        }
      }
    }
    .body {
      clear: both;
      padding: 10px 10px;
      position: relative;
      min-height: 74px;
      .percent {
        position: relative;
      }
      .count {
        text-align: center;
        .number {
          text-align: center;
          font-size: 48px;
          line-height: 54px;
          font-weight: bold;
          padding-bottom: 0;
        }
        .goal {
          position: absolute;
          right: 0;
          padding-right: 20px;
          color: @label-color;
          top: 15px;
          p {
            font-size: @font-small;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: @gray-dark;
          }
          label {
            font-size: @font-extra-extra-large;
            line-height: 1;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.target {
  &.has-goal,
  &.has-goal:not(.goal-met),
  .percent {
    .number {
      color: @targets-below-color;
      padding-bottom: 5px;
    }
    .progress-bar {
      background-color: @targets-below-color;
    }
  }
  &.goal-met,
  .percent {
    .number {
      color: @targets-above-goal;
      padding-bottom: 5px;
    }
    .progress-bar {
      background-color: @targets-above-goal;
    }
  }
  &:not(.has-goal) .goal {
    display: none;
  }

  .target-progress {
    position: relative;
    .goal {
      padding-top: 5px;
      position: absolute;
      top: -40px;
      margin-top: 10px;
      label {
        margin-left: -40%; /* center aligned */
        white-space: nowrap;
        font-size: @font-small;
        font-weight: normal;
        color: @label-color;
        &.pin-left {
          margin-left: 0; /* left aligned */
        }
        &.pin-right {
          margin-left: -105%; /* right aligned */
        }
      }
      .bar {
        position: absolute;
        top: 25px;
        height: 30px;
        width: 8px;
        background-color: @label-color;
        border: 2px solid @target-progress-bar-border-color;
        border-radius: @radius-size;
      }
      .adjust-margin {
        margin-left: -10px
      }
    }

    .value {
      font-size: @font-medium;
      line-height: 1;
      font-weight: bold;
      padding-left: 6px;
    }
  }
}

.target-aggregates {
  max-width: @content-width;
  left: 0;
  right: 0;
}

#target-aggregates-list {
  .content-row {
    &.selected {
      background-color: @analytics-highlight;
    }

    .aggregate-status {
      color: @text-secondary-color;
      white-space: nowrap;
      &.error {
        font-weight: bold;
        color: @targets-below-color;
      }
      &.success {
        font-weight: bold;
        color: @targets-above-goal;
      }
    }
  }
}

.aggregate-detail {
  .target {
    .target-progress {
      .progress {

        &.placeholder {
          background: @targets-progress-bar-background-color;
        }

        .progress-bar {
          font-weight: bold;
          text-align: left;
          color: @text-normal-color;

          &.no-border {
            border: none;
          }

          span {
            font-size: @font-small;
            padding: 0 @radius-size-small;
            color: @progress-bar-background-color;
          }
        }
      }

      .goal {
        width: 100%;
        label {
          position: absolute;
          right: 0;
        }
      }
    }
  }
}

@media (max-width: @media-tablet) {
  .targets {
    grid-template-columns: repeat(2, 296px);
  }
}

@media (max-width: @media-mobile) {
  .targets {
    padding: 0;
    grid-template-columns: 1fr;
    .target {
      width: calc(100% - @targets-column-gap);
      margin: 10px 12px 0 12px;
    }
    .target:last-child {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: @media-small-mobile) {
  .targets {
    padding: 0;
    grid-template-columns: 1fr;
    .target {
      width: calc(100% - @targets-column-gap);
      margin: 10px 12px 0 12px;
    }
    .target:last-child {
      margin-bottom: 10px;
    }
  }
}
